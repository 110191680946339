import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { colors } from '../styles/colors'

import Layout from '../components/layout'
import { Section } from '../components/sections'
import { Grid } from '../components/grid'
import { Button } from '../components/button'

const SectionHeading = styled.p`
  color: ${colors.quaternary.default};
`

const BrandList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Founders Grotesk Mono';
  font-size: 14px;

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const createBrandUrl = fileName => {
  return fileName
    .split('/')
    .pop()
    .replace('.md', '')
}

const AboutPage = props => (
  <StaticQuery
    query={graphql`
      query AboutPageQuery {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { path: { eq: "/about" } }) {
          html
          excerpt(pruneLength: 160)
          frontmatter {
            title
            brands {
              name
              caseStudy
              url
            }
            seoTitle
            seoDescription
            seoImage
          }
        }
        seo: file(
          relativePath: { eq: "seo.md" }
          sourceInstanceName: { eq: "data" }
        ) {
          data: childMarkdownRemark {
            frontmatter {
              image
              description
              siteTitle
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            {
              name: 'description',
              content:
                data.markdownRemark.frontmatter.seoDescription ||
                data.markdownRemark.excerpt ||
                data.seo.data.frontmatter.description,
            },
            {
              name: 'og:image',
              content:
                'https://madebypioneer.com' +
                (data.markdownRemark.frontmatter.seoImage ||
                  data.seo.data.frontmatter.image),
            },
          ]}
          title={`${data.markdownRemark.frontmatter.seoTitle ||
            data.markdownRemark.frontmatter.title} | ${
            data.seo.data.frontmatter.siteTitle
          }`}
        />
        <Section>
          <Grid
            tablet={{ columns: 'repeat(2, 1fr)' }}
            desktop={{ columns: 'repeat(2, 1fr)' }}
            offset={1}
          >
            <div>
              <h1 className="h4">{data.markdownRemark.frontmatter.title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
            </div>
            <div>
              <SectionHeading className="blockquote">
                Brands We've Worked With
              </SectionHeading>
              {data.markdownRemark.frontmatter.brands.length && (
                <BrandList>
                  {data.markdownRemark.frontmatter.brands.map((brand, i) => (
                    <li key={i}>
                      {brand.caseStudy ? (
                        <Button
                          to={`/work/${createBrandUrl(brand.caseStudy)}`}
                          medium
                        >
                          {brand.name}
                        </Button>
                      ) : brand.url ? (
                        <a
                          href={brand.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button medium>{brand.name}</Button>
                        </a>
                      ) : (
                        brand.name
                      )}
                    </li>
                  ))}
                </BrandList>
              )}
            </div>
          </Grid>
        </Section>
      </Layout>
    )}
  />
)

export default AboutPage
